@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800");

@layer base {
  html {
    font-size: 12px;
    @apply text-zinc-600;
  }

  /* Scrollbar */
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #cecece;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
}

@layer components {
  .form {
    @apply block w-full px-3 h-12 text-base rounded-sm border border-gray-200  focus:ring-0  placeholder:text-gray-400  disabled:bg-gray-100 disabled:text-gray-500;
  }

  .card-title {
    @apply flex-shrink-0 font-medium tracking-wider capitalize;
  }

  .table {
    @apply min-w-full divide-y divide-gray-100 border border-gray-100;
  }

  .table-head {
    @apply h-16 px-4 text-left truncate font-medium tracking-wider capitalize bg-gray-50;
  }

  .table-cell {
    @apply py-4 px-4 text-nowrap;
  }

  .badge {
    @apply inline-flex items-center text-sm px-4 py-2 font-semibold;
  }

  .badge-light {
    @apply text-gray-500 bg-gray-100;
  }

  .badge-danger {
    @apply bg-red-100 text-red-800;
  }

  .badge-warning {
    @apply bg-yellow-100 text-yellow-800;
  }
  .badge-success {
    @apply bg-green-100 text-green-800;
  }
  .badge-primary {
    @apply bg-blue-100 text-blue-800;
  }
  .icon-xs {
    @apply w-3 h-3;
  }
  .icon-sm {
    @apply w-6 h-6;
  }
  .icon-md {
    @apply w-6 h-6;
  }
  .icon-lg {
    @apply w-10 h-10;
  }
}
