.toast-success {
  background-color: green !important;
  color: white !important;
  font-size: larger;
}

.toast-warning {
  background-color: orange !important;
  color: white !important;
  font-size: larger;
}
